.container-category, .category-container {
  .field-name-body code,
  .category-description code {
    display: none;
  }
}

.region-sidebar-second {
  img {
    //width: 100% !important;
    height: auto !important;
    max-width: 350px;
    @media (min-width: 992px) {
      max-width: 100%;
    }
  }
}

.footer {
  background: black;
  color: white;
  padding: 0;
  margin-top: 32px;
  * {
    line-height: initial;
  }
  a {
    color: white;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.login-page .footer {
  background: #f9f9f9;
  color: black;
  padding: 10px;
  margin-left: -50px;
}

#footer {
  overflow: hidden;
  //margin-top: 30px;
  clear: both;
  padding: 15px 0 0;
  width: 100%;
  background-color: #000;
  //min-height: 300px;
  //position: absolute;
  bottom: 0;
  z-index: 9;
}

footer {
  display: block;

  * {
    box-sizing: border-box;
  }

  .col-xs-24 {
    width: 100%;
    float: left;
  }
  .col-lg-8 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }

  nav {
    display: block;
    & > li > a:hover {

    }
  }

  .nav {
    list-style: none;

    &:before,
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }

  ul {
    padding: 0;
    margin: 0;
  }
  ul li, ol li {
    line-height: 1.475;
    list-style: none;
  }

  .visible-xs {
    display: none !important;
  }

  .visible-sm {
    display: none !important;
  }

  .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;

    &:before {
      content: " ";
      display: table;
    }
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;

    &:before {
      content: " ";
      display: table;
    }
  }

  hr {
    box-sizing: content-box;
    height: 0;
    border: 0;
    border-top: 1px solid #eee;
    border-color: #999;
    margin: 15px 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.475;
    margin: 0 0 12px 0;
    max-width: 690px;
  }

  #site-footer-menu {
    font-weight: 300;

    .menu-name-main-menu>ul.menu.nav>li {
      border-right: 1px solid #202020;
      &.last {
        border-right: none;
      }
      &.first {
        display: none;
      }

      a {
        margin-bottom: 16px;
        padding: 0;
        font-size: 16px;

        &:hover {
          text-decoration: underline;
          background-color: transparent;
        }
      }
      ul.menu.nav {
        width: 100%;
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        box-shadow: none;
        text-size-adjust: 100%;
        -webkit-font-smoothing: antialiased;
        position: static;
        display: inline-block;
        &>li {
          float: none;
          margin-bottom: 14px;
          width: 100%;

          a {
            white-space: normal;
            margin-bottom: 0;
            font-size: 14px;
            font-weight: 100;
          }
        }
      }
    }

    .menu-name-main-menu>ul.menu.nav>li {
      list-style: none;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
      width: 33.33333%;
      float: left;
    }
  }

  .copyright {
    font-size: 14px;
    color: #999;
    padding: 0;
    margin-bottom: 15px;
    white-space: nowrap;
  }

  ul.legal-menu {
    font-weight: 300;
    display: inline-block;

    li {
      margin-bottom: 15px;
      float: left;
      border-right: 1px solid #202020;
      padding: 0 6px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
      /*
      &:after {
        content: "|";
        margin: 0 3px;
      }

      &:last-child:after {
        display: none;
      }
      */

      a {
        color: #fff;
        font-size: 14px;
        &:active, &:focus, &:hover {
          background-color: transparent;
          text-decoration: underline;
        }
      }
    }
  }

  ul#social-icons {
    margin: -6px 0 10px 0 !important;

    li {
      display: inline-block;
      padding: 0 10px;
      font-size: 22px;

      a {
        color: #fff;
      }
    }
  }



  .flag-icon-background, .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat
  }

  .flag-icon {
    position: relative;
    display: inline-block;
    width: 1.33333em;
    line-height: 1em;

    &:before {
      content: '\00a0';
    }
  }

  .flags-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;

    .flag-icon {
      border: 1px solid #333;
    }
  }

  .flag-icon.flag-icon-squared {
    width: 1em
  }

  .flag-icon-at {
    background-image: url("../../images/flags/4x3/at.png")
  }

  .flag-icon-at.flag-icon-squared {
    background-image: url("../../images/flags/1x1/at.png")
  }

  .flag-icon-be {
    background-image: url("../../images/flags/4x3/be.png")
  }

  .flag-icon-be.flag-icon-squared {
    background-image: url("../../images/flags/1x1/be.png")
  }

  .flag-icon-bg {
    background-image: url("../../images/flags/4x3/bg.png")
  }

  .flag-icon-ch {
    background-image: url("../../images/flags/4x3/ch.png")
  }

  .flag-icon-ch.flag-icon-squared {
    background-image: url("../../images/flags/1x1/ch.png")
  }

  .flag-icon-cz {
    background-image: url("../../images/flags/4x3/cz.png")
  }

  .flag-icon-cz.flag-icon-squared {
    background-image: url("../../images/flags/1x1/cz.png")
  }

  .flag-icon-de {
    background-image: url("../../images/flags/4x3/de.png")
  }

  .flag-icon-de.flag-icon-squared {
    background-image: url("../../images/flags/1x1/de.png")
  }

  .flag-icon-dk {
    background-image: url("../../images/flags/4x3/dk.png")
  }

  .flag-icon-dk.flag-icon-squared {
    background-image: url("../../images/flags/1x1/dk.png")
  }

  .flag-icon-ee {
    background-image: url("../../images/flags/4x3/ee.png")
  }

  .flag-icon-ee.flag-icon-squared {
    background-image: url("../../images/flags/1x1/ee.png")
  }


  .flag-icon-es {
    background-image: url("../../images/flags/4x3/es.png")
  }

  .flag-icon-es.flag-icon-squared {
    background-image: url("../../images/flags/1x1/es.png")
  }

  .flag-icon-fi {
    background-image: url("../../images/flags/4x3/fi.png")
  }

  .flag-icon-fi.flag-icon-squared {
    background-image: url("../../images/flags/1x1/fi.png")
  }

  .flag-icon-fr {
    background-image: url("../../images/flags/4x3/fr.png")
  }

  .flag-icon-fr.flag-icon-squared {
    background-image: url("../../images/flags/1x1/fr.png")
  }


  .flag-icon-gb {
    background-image: url("../../images/flags/4x3/gb.png")
  }

  .flag-icon-gb.flag-icon-squared {
    background-image: url("../../images/flags/1x1/gb.png")
  }

  .flag-icon-gr {
    background-image: url("../../images/flags/4x3/gr.png")
  }

  .flag-icon-gr.flag-icon-squared {
    background-image: url("../../images/flags/1x1/gr.png")
  }

  .flag-icon-hr {
    background-image: url("../../images/flags/4x3/hr.png")
  }

  .flag-icon-hr.flag-icon-squared {
    background-image: url("../../images/flags/1x1/hr.png")
  }

  .flag-icon-hu {
    background-image: url("../../images/flags/4x3/hu.png")
  }

  .flag-icon-hu.flag-icon-squared {
    background-image: url("../../images/flags/1x1/hu.png")
  }

  .flag-icon-ie {
    background-image: url("../../images/flags/4x3/ie.png")
  }

  .flag-icon-ie.flag-icon-squared {
    background-image: url("../../images/flags/1x1/ie.png")
  }

  .flag-icon-il {
    background-image: url("../../images/flags/4x3/il.png")
  }

  .flag-icon-il.flag-icon-squared {
    background-image: url("../../images/flags/1x1/il.png")
  }

  .flag-icon-is {
    background-image: url("../../images/flags/4x3/is.png")
  }

  .flag-icon-is.flag-icon-squared {
    background-image: url("../../images/flags/1x1/is.png")
  }

  .flag-icon-it {
    background-image: url("../../images/flags/4x3/it.png")
  }

  .flag-icon-it.flag-icon-squared {
    background-image: url("../../images/flags/1x1/it.png")
  }

  .flag-icon-lt {
    background-image: url("../../images/flags/4x3/lt.png")
  }

  .flag-icon-lt.flag-icon-squared {
    background-image: url("../../images/flags/1x1/lt.png")
  }

  .flag-icon-lu {
    background-image: url("../../images/flags/4x3/lu.png")
  }

  .flag-icon-lu.flag-icon-squared {
    background-image: url("../../images/flags/1x1/lu.png")
  }

  .flag-icon-lv {
    background-image: url("../../images/flags/4x3/lv.png")
  }

  .flag-icon-lv.flag-icon-squared {
    background-image: url("../../images/flags/1x1/lv.png")
  }

  .flag-icon-ma {
    background-image: url("../../images/flags/4x3/ma.png")
  }

  .flag-icon-ma.flag-icon-squared {
    background-image: url("../../images/flags/1x1/ma.png")
  }

  .flag-icon-me {
    background-image: url("../../images/flags/4x3/me.png")
  }

  .flag-icon-me.flag-icon-squared {
    background-image: url("../../images/flags/1x1/me.png")
  }

  .flag-icon-mk {
    background-image: url("../../images/flags/4x3/mk.png")
  }

  .flag-icon-mk.flag-icon-squared {
    background-image: url("../../images/flags/1x1/mk.png")
  }

  .flag-icon-nl {
    background-image: url("../../images/flags/4x3/nl.png")
  }

  .flag-icon-nl.flag-icon-squared {
    background-image: url("../../images/flags/1x1/nl.png")
  }

  .flag-icon-no {
    background-image: url("../../images/flags/4x3/no.png")
  }

  .flag-icon-no.flag-icon-squared {
    background-image: url("../../images/flags/1x1/no.png")
  }

  .flag-icon-pt {
    background-image: url("../../images/flags/4x3/pt.png")
  }

  .flag-icon-pt.flag-icon-squared {
    background-image: url("../../images/flags/1x1/pt.png")
  }

  .flag-icon-ro {
    background-image: url("../../images/flags/4x3/ro.png")
  }

  .flag-icon-ro.flag-icon-squared {
    background-image: url("../../images/flags/1x1/ro.png")
  }

  .flag-icon-rs {
    background-image: url("../../images/flags/4x3/rs.png")
  }

  .flag-icon-rs.flag-icon-squared {
    background-image: url("../../images/flags/1x1/rs.png")
  }

  .flag-icon-se {
    background-image: url("../../images/flags/4x3/se.png")
  }

  .flag-icon-se.flag-icon-squared {
    background-image: url("../../images/flags/1x1/se.png")
  }

  .flag-icon-si {
    background-image: url("../../images/flags/4x3/si.png")
  }

  .flag-icon-si.flag-icon-squared {
    background-image: url("../../images/flags/1x1/si.png")
  }

  .flag-icon-sk {
    background-image: url("../../images/flags/4x3/sk.png")
  }

  .flag-icon-sk.flag-icon-squared {
    background-image: url("../../images/flags/1x1/sk.png")
  }

  .flag-icon-tr {
    background-image: url("../../images/flags/4x3/tr.png")
  }

  .flag-icon-tr.flag-icon-squared {
    background-image: url("../../images/flags/1x1/tr.png")
  }

  .flags-wrapper .break {
    flex-basis: 100%;
    width: 0px;
    overflow: hidden;
}

}

@media (max-width:867px) {
  footer {
    .visible-xs {
      display: block !important;
    }
    .text-center-xs {
      text-align: center;
    }
    ul#social-icons li {
      font-size: 32px
    }

    .hidden-xs,
    #site-footer-menu .menu-name-main-menu>ul.menu.nav>li ul.menu.nav {
      display: none !important;
    }

    .flags-wrapper .flag-icon {
      width: 15px;
      height: 11px
    }

    .flags-wrapper .break {
      height: 5px
    }
  }
}

@media (min-width:868px) and (max-width:991px) {
  .flags-wrapper .flag-icon {
    width: 24px;
    height: 18px
  }

  .flags-wrapper .break {
    height: 15px
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .flags-wrapper .flag-icon {
    width: 20px;
    height: 15px
  }
}

@media (min-width:1200px) and (max-width:1469px) {
  .flags-wrapper .flag-icon {
    width: 22px;
    height: 17px
  }
}

@media (min-width:1470px) {
  .flags-wrapper .flag-icon {
    width: 24px;
    height: 18px
  }
}

@media (max-width: 991px) and (min-width: 868px) {
  footer .visible-sm {
    display: block !important;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .text-center-sm {
      text-align: center;
  }
  .text-right-sm {
    text-align: right;
  }
  .text-left-sm {
    text-align: left;
  }
}
@media (min-width: 868px) {
  footer {
    .col-sm-12 {
      width: 50%;
      float: left;
    }
    .col-sm-24 {
      width: 100%;
      float: left;
      position: relative;
      min-height: 1px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .container-fluid {
      width: 750px;
    }

    #site-footer-menu .menu-name-main-menu>ul.menu.nav>li {
      width: 50%;
      float: left;
    }
  }
}

@media (min-width: 992px) {
  footer {
    font-size: 16px;
    ul li, ol li {
      line-height: 1.5625;
    }
    .container-fluid {
      width: 970px;
    }

    #site-footer-menu .menu-name-main-menu>ul.menu.nav>li {
      width: 33.33333%;
      float: left;
    }

    p {
      font-size: 16px;
      line-height: 1.5625;
    }
  }
}

@media (min-width: 1200px) {
  #footer {
    min-height: 385px;
  }
  footer {
    .text-left-lg {
      text-align: left;
    }
    .text-right-lg {
      text-align: right;
    }
    .text-center-lg {
      text-align: center;
    }
    .col-lg-push-8 {
      left: 33.33333%;
    }
    .col-lg-pull-8 {
      right: 33.33333%;
    }
    .col-lg-8 {
      width: 33.33333%;
      float: left;
    }
    .container-fluid {
      width: 1170px;
    }
    #site-footer-menu .menu-name-main-menu>ul.menu.nav>li {
      width: 16.66667%;
      float: left;
    }
  }
}

@media (min-width: 1470px) {
  footer {
    .container-fluid {
      width: 1440px;
    }
  }
}



@media (max-width: 1199px) and (min-width: 992px) {
  footer {
    min-height: 670px;
    .text-center-md {
      text-align: center;
    }
    .text-right-md {
      text-align: right;
    }

    #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+1) {
      border-right: none
    }

    #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+1):after {
      content: "";
      display: table;
      clear: both
    }

    #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+2) {
      clear: both
    }

    .flags-wrapper .flag-icon {
        width: 20px;
        height: 15px;
    }
  }
}

@media (max-width:867px) {
  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li {
    border-right: none;
    text-align: center
  }
}

@media (min-width:868px) and (max-width:991px) {
  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li {
    margin-bottom: 15px
  }

  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(odd) {
    border-right: none
  }

  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(even) {
    clear: both
  }
}

@media (min-width:992px) and (max-width:1199px) {
  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+1) {
    border-right: none
  }

  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+1):after {
    content: "";
    display: table;
    clear: both
  }

  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:nth-child(3n+2) {
    clear: both
  }
}

@media (min-width:1200px) {
  #footer #site-footer-menu .menu-block-wrapper ul.menu.nav li:last-child {
    border-right: none
  }
}

.ecmwf_header {
  * {
    outline: none;
  }
  ul li, ol li {
    line-height: 1.475;
  }
  .element-invisible {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px,1px,1px,1px);
    overflow: hidden;
    height: 1px;
  }
  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 1.42857;
    border-radius: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .btn-sm {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.5;
    border-radius: 0px;
  }
  .visible-xs {
    display: none !important;
  }

  .input-sm {
    height: 33px;
    padding: 5px 10px;
    line-height: 1.5;
    border-radius: 0px;
  }

  a#user-settings-link {
    color: white;
  }
  #event-management-btn {
    float: right;
    display: block;
    margin-top: 7px;
  }
  .menu-collapse-overlay {
    display: none;
    z-index: 5;
    position: fixed;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .5;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
  }
  #header {
    display: block;
    z-index: 10;
  }
  .white-line{
    height: 1px;
    background-color: black;
    position: absolute;
    z-index: 1000000000;
    width: 100%;
    top: 48px;
  }
  .white-line.white {
    background-color: white;
  }
  .navbar {
    position: relative;
    min-height: 48px;
    border: 1px solid transparent;
  }
  .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .navbar-nav {
    margin: 6.5px -15px;
  }
  .nav:after {
    clear: both;
  }
  .nav:before, .nav:after {
    display: table;
    content: " ";
  }
  .navbar-inverse {
    background-color: #000000;
  }
  .navbar-inverse .container {
    background-color: #000000;
  }
  .navbar-fixed-top {
    top: 0;
    right: 0;
    left: 0;
    z-index: 10;
  }
  .navbar-fixed-top .navbar-collapse,
  .navbar-fixed-bottom .navbar-collapse {
    max-height: 340px;
  }
  .navbar-collapse:before, .navbar-collapse:after {
    display: table;
    content: " ";
  }
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-right: 15px;
    margin-top: 7px;
    margin-bottom: 7px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 0px;
    cursor: pointer;
  }
  .navbar-toggle:hover {
    opacity: 0.8;
  }
  .navbar-toggle:focus {
    outline: none;
    border: none;
  }
  #header:after,
  #app-menu .container:before,
  #app-menu .container:after,
  nav .container:before,
  nav .container:after {
    display: table;
    content: " ";
  }
  #app-menu .container,
  nav .container {
    padding-right: 15px;
    padding-left: 15px;
    margin: 0 auto;
    height: auto;
    min-height: 0;
    box-sizing: border-box;
  }
  .nav > li {
      position: relative;
      display: block;
  }
  .nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    //line-height: 22px;
  }

  .container > .navbar-collapse,
  .container-fluid > .navbar-header,
  .container-fluid > .navbar-collapse {
    margin-right: -15px;
    margin-left: -15px;
  }
  .navbar-brand {
    float: left;
    height: 48px;
    padding: 13px 15px;
    font-size: 20px;
    line-height: 22px;
  }
  .navbar-header:before, .navbar-header:after {
    display: table;
    content: " ";
  }
  .header-menu, .navbar-toggle, nav {
    border: none !important;
  }
  .navbar-inverse .navbar-toggle {
    border-color: #333;
  }
  .header-menu,
  .navbar-toggle,
  nav {
    border: none !important;
  }
  #header .collapse {
    display: none;
  }
  #header nav .navbar-header .navbar-toggle {
    padding: 0;
    height: 48px;
    width: 48px;
    font-size: 24px;
    color: #fff;
    margin: 0;
    -webkit-transition: all 0.125s ease-in-out;
    -moz-transition: all 0.125s ease-in-out;
    -o-transition: all 0.125s ease-in-out;
    transition: all 0.125s ease-in-out;

      &.active {
        background-color: #fff !important;
        color: #000 !important;
    }
  }
  .navbar-inverse .navbar-brand {
    color: #9d9d9d;
  }
  #header nav .navbar-header #header-logo {
    background: url("../../images/logo.production.png");
    background-size: 140px 24px;
    background-repeat: no-repeat;
    background-position: center;
    float: left;
    width: 140px;
    height: 48px;
    margin: 0 15px 0 0;
    padding: 0;
    padding-left: 15px;
  }

  #header-user, html body #app-menu .row ul#help-menu {
    margin-right: -15px;
  }

  #header nav #navbar-user #header-user a {
    font-size: 14px;
    border: none;
    background-color: transparent;
  }
  #header nav #navbar-user #header-user a:hover {
    text-decoration: underline;
  }
  #header nav #navbar-user #header-user i {

  }
  .navbar-inverse .navbar-nav > li > a {
    color: #fff !important;
  }

  #header nav #navbar-menu {
    clear: both;
  }
  #header nav #navbar-menu #header-menu {
    text-align: center;
    margin: 0;
    width: 100%;
    float: none;
  }
  #header nav #navbar-menu #header-menu li {
    float: none;
    display: inline-block;
    margin: 0;
  }
  #header nav #navbar-menu #header-menu li a {
    font-size: 20px;
    padding: 20px;
    border-top: transparent solid 2px;
    border-bottom: transparent solid 5px;
    color: #fff;
    border: none;
    background-color: transparent;
    font-size: 20px;
    padding: 10px 12px;
    border-top: transparent solid 3px;
    border-bottom: transparent solid 5px;
    -webkit-transition: border-color .125s;
    -moz-transition: border-color .125s;
    -o-transition: border-color .125s;
    transition: border-color .125s;
  }
  #header nav #navbar-menu #header-menu li a:hover,
  #header nav #navbar-menu #header-menu li a.active {
    text-decoration: none;
    border-color: #fff;
    background-color: transparent;
    font-weight: normal;
  }
  #header nav #navbar-user ul li span.fa {
    margin-right: 8px;
  }
  #header-user #session-bar {
    display: inline-block;
  }
  #app-menu {
    background-color: white;
    color: black;
    text-align: center;
  }
  #app-menu .container {
    border: none;
    background-color: transparent;
  }

  #app-menu ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin: 12px auto;
  }

  #app-menu li {
    display: inline-block;
    list-style: none;
    position: relative;
    line-height: 1.5625;
  }
  #app-menu .btn-group,
  #app-menu.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  #app-menu #main-menu > li:after {
    content: "";
    border-left: 1px solid #999;
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    display: none;
  }
  #app-menu #help-menu > li .btn-group > a,
  #app-menu #main-menu > li .btn-group > a {
    color: #333 !important;
    padding: 5px 15px;
    font-size: 14px;
    display: block;
    border-right: 1px

  }
  #app-menu #main-menu > li > .btn-group > a {
    padding: 4px 8px;

    &.active {
      font-weight: 700;
    }
  }
  #app-menu #main-menu > li:first-child {
    margin-left: -14px;
  }

  .container,
  .navbar-fixed-top .container {
    width: 100%;
  }

  #navbar-search {
    border-color: transparent;
    box-shadow: none;
    padding: 0;
    margin-left: -15px;
    margin-right: -16px;

    .input-group {
      position: relative;
      display: table;
      border-collapse: separate;
      width: 100%;
      padding: 6px 0;
      background-color: #fff;
      border-bottom: 1px solid #999;

      * {
        border-color: transparent;
      }

      .form-control {
        color: #555;
        position: relative;
        z-index: 2;
        float: left;
        width: 100%;
        margin-bottom: 0;
        box-shadow: none;
      }

      .input-group-btn {
        display: table-cell;
        vertical-align: middle;
        width: 5%;
        position: relative;
        font-size: 0;
        white-space: nowrap;
      }

      input {
        width: 100%;
        font-style: italic;
        font-size: 14px;
        -webkit-transition: all .25s ease-in-out;
        -moz-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
      }

      button {
        background-color: #fff;
        color: #333;
      }

      .input-group-btn span.fa {
        font-size: 24px;
      }

    }
  }

  #navbar-search .input-group-btn:last-child>.btn{
    z-index: 2;
    margin-left: -1px;
  }

  @media (max-width: 768px) {

    #header nav #navbar-user #header-user {
      text-align: center;
      margin: 0;
      width: 100%;
      float: none;
    }

    #header nav #navbar-user #header-user li {
      float: none;
      display: inline-block;
      margin: 0 10px;
    }

    #header nav #navbar-user #header-user li a {
      font-size: 20px;
      padding: 20px;
      border-top: transparent solid 2px;
      border-bottom: transparent solid 5px;
    }

    #header nav #navbar-user #header-user li a:hover {
      text-decoration: none;
      border-color: #fff;
      background-color: transparent;
    }
  }

  @media (max-width: 867px) {
    .hidden-xs {
      display: none !important;
    }
    #header nav #navbar-user,
    #header nav #navbar-search,
    #header nav #navbar-menu {
      clear: both;
      display: none;
    }
    #header nav #navbar-menu #header-menu li a.active {
    background-color: #fff;
    color: #000 !important;
    }
  }

  @media (min-width: 768px) {

    .navbar-fixed-top, .navbar-fixed-bottom {
      border-radius: 0;
    }
    .navbar-nav {
      float: left;
      margin: 0;
    }
    .navbar-nav > li {
      float: left;
    }

    .container > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container-fluid > .navbar-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .navbar-header {

    }

    #app-menu .container, {
      width: 750px;
    }
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse,
    .navbar-fixed-bottom .navbar-collapse {
      padding-right: 0;
      padding-left: 0;
    }

    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none;
    }

    .navbar-nav > li > a {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  @media (min-width: 868px) {
    .navbar > .container {
        width: 750px;
    }
    .navbar-header {
      float: left;
    }
    .navbar-right {
      float: right !important;
      margin-right: -15px;
    }
    .navbar-collapse.collapse {
      display: block !important;
      height: auto !important;
      padding-bottom: 0;
      overflow: visible !important;
    }

    .navbar-right {
      float: right !important;
      margin-right: 0;
      padding-right: 15px;
      width: auto;
    }
    .navbar-toggle {
        display: none;
    }
    #app-menu ul {
      margin: 12px auto;
    }
    #app-menu #main-menu > li {
      margin: 6px 0;
    }
    #app-menu #main-menu > li:after {
      display: block;
    }
    #app-menu #main-menu > li:last-child:after {
      display: none;
    }
    #app-menu #main-menu > li > .btn-group > a {
      padding: 2px 16px;
    }
    #header nav #navbar-menu #header-menu li a {
      font-size: 18px;
    }
    #navbar-search {
      display: block !important;
      height: auto !important;
      overflow: visible !important;
      margin: 7px 0 7px 0;
      padding: 0 15px;

      .input-group {
        border: none;
        padding: 0;
        background-color: transparent;

        input.form-control {
          width: 120px;
          background-color: #333;
          &:focus {
            width: 180px;
            background-color: #fff;
          }
        }
        .input-group-btn {
          width: auto;
          span.fa {
            font-size: inherit;
          }
        }
      }
    }
  }

  @media (min-width: 992px) {
    ul li, ol li {
      line-height: 1.5625;
    }
    #app-menu .container,
    .navbar > .container {
        width: 970px;
    }
    #header nav .navbar-header #header-logo {
      padding-left: 0;
    }

    #header nav #navbar-menu #header-menu li a {
      padding: 12px 18px;
      font-size: 20px;
    }

    #app-menu #main-menu > li .btn-group > a {
      font-size: 16px;
    }
    #app-menu #main-menu > li > .btn-group > a {
      padding: 2px 20px;
    }
  }

  @media (min-width: 1200px) {
    #app-menu .container,
    .navbar > .container {
        width: 1170px;
    }
    #header nav #navbar-menu #header-menu li {
      margin: 0 10px;
    }
    #navbar-search {
      .input-group input:focus {
        width: 260px;
      }
    }
  }

  @media (min-width: 1470px) {
    .navbar > .container {
        width: 1440px;
    }
  }
}

.menu-collapse-overlay {
    display: none;
    z-index: 5;
    position: fixed;
    background-color: #000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .5;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
}


.main-breadcrumb  {
  display: none !important;
}

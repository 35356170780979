.ecmwf-page {

@import 'base';

.agenda-placeholder {
  background-color: white;
  margin-top: 10px;
  flex-grow: 1;
  color: $gray;
  text-align: center;
  height: 250px;

  .placeholder-icon {
      font-size: 5em;
  }

  .agenda-placeholder-content {
      margin: auto;
  }

  .placeholder-text {
      font-size: 1.5em;
      font-weight: bold;
  }

  a {
      color: $link;
  }

  a:hover {
      text-decoration: underline;
  }
}

.livestreamed {
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

// ECMWF timetable
table.time {
  border-collapse: collapse;
}

table.time tr.day-header {
  background-color: #1576b5;
}

table.time td {
  border-bottom: solid 1px #99c3df;
  border-top: solid 1px #99c3df;
  padding: 5px 10px 5px 10px;
  vertical-align: top;
}

table.time tr.day-header td {
  white-space: nowrap;
  color: #fff;
  font-size: 1em;
  padding: 5px 10px 5px 10px;
  vertical-align: top;
}

table.time td div.title {
  font-weight: bold;
}

table.time td div.attachments {
  margin: 3px -3px;
}

table.time tr.day-header td.jump-calendar {
  padding: 5px;
  padding-left: 20px; 
}

table.time a.js-go-to-day {
  color: white;
}

table.time a:hover {
  text-decoration: none;
}

table.time ul.dropdown a {
  font-size: 1em;
}

table.time td.time, table.time td.duration {
  white-space: nowrap;
}

table.time td.duration {
  text-align: right;
}

table.time td.location {
  max-width: 80px;
}

table.time td.manage-button {
  padding: 0;
}

table.time tr.non-empty-session {
  background-color: #d0ebf4;
}

table.time div.speaker-list {
  font-size: 1em;
  margin-bottom: 5px;
}

table.time td div.attachments {
  margin: 4px 0;
}

table.time td div.attachments a.attachment {
  margin-left: 0 !important;
}

table.time div.material-list div.attachments-display-container,
table.time div.material-list div.folder {
  display: block;
  max-width: 150px;
}

table.time div.material-list div.folder a {
  justify-content: left;
  max-width: 260px;
  margin-top: -5px !important;
}

table.time div.material-list div.folder a span.title {
  font-size: 1em;
}

table.time .item-location {
  color: #555;
  display: flex;
}

table.time .item-location i{
  padding: 1px 2px 0 0;
}

table.time div.material-list div.folder a[href$="/go"]:before {
  content: "" !important; // This is the video play character
}

@media (max-width: 1200px) {
  // Remove manage button on small screens
  table.time td.manage-button {
    display: none;
  }
}
}
@media print {
    body .header,
    body .footer {
        display: none;
    }

    main .container-category.middle .row {
        .col-sm-8.col-md-6,
        div.sub-title {
            display: none;
        }
    }

    table td div.attachments {
        display: none;
    }

    table td .icon-location {
        display: none;
    }

    body {
        display: block;
    }

    #MathJax_Message {
        display: none;
    }
}